<template>
  <div class="orders--page">
   
   <SideNav/>
    <div class="orders--container">
      <Returns/>
    </div>
  </div>
</template>

<script>


import Returns from "../components/Returns";
import SideNav from "../components/SideNav";

export default {
  components: {
   SideNav,
    Returns
  },


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>



</style>