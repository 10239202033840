import axios from "axios";
var qs = require("qs");
var assert = require("assert");

var obj = qs.parse("a=c");
assert.deepEqual(obj, { a: "c" });

var str = qs.stringify(obj);
assert.equal(str, "a=c");

var withArray = qs.parse("a[]=b&a[]=c");
assert.deepEqual(withArray, { a: ["b", "c"] });

const state = {
  orders: [],
  pendingOrders: [],
  ordersToShip: [],
  fulfilledOrders: [],
  loading: false,
  tires: []
};

const getters = {
  orders: (state) => state.orders,
  pendingOrders: (state) => state.pendingOrders,
  ordersToShip: (state) => state.ordersToShip,
  fulfilledOrders: (state) => state.fulfilledOrders,
  tires: (state) => state.tires
  

};

const actions = {
  loadOrders({ commit }) {
    const params = {};
   
    params.user = localStorage.getItem('User');

    axios.get(
      process.env.VUE_APP_ORDERS,
        {
          params: params,
          paramsSerializer: function(params) {
            return qs.stringify(params);
          },
       
        },

      )
      .then((res) => {
        commit("SET_ORDERS", res.data);
        console.log(res);
      })
      .catch((error) => console.log(error));
  },
  loadTires({ commit }) {
    const params = {};
   
    // params.user = localStorage.getItem('User');

    axios.get(
      process.env.VUE_APP_TIRES,
        {
          params: params,
          paramsSerializer: function(params) {
            return qs.stringify(params);
          },
       
        },

      )
      .then((res) => {
        commit("SET_TIRES", res.data);
        console.log(res);
      })
      .catch((error) => console.log(error));
  },
  loadPendingOrders({ commit }) {
    const params = {};
   
    params.user = localStorage.getItem('User');

    axios.get(
      process.env.VUE_APP_PENDING_ORDERS,
        {
          params: params,
          paramsSerializer: function(params) {
            return qs.stringify(params);
          },
       
        },

      )
      .then((res) => {
        commit("SET_PENDING_ORDERS", res.data);
        console.log(res);
      })
      .catch((error) => console.log(error));
  },
  loadOrdersToShip({ commit }) {
    const params = {};
    params.user = localStorage.getItem('User');
    axios
      .get(
        process.env.VUE_APP_SOLD_OUT, 
        {
          params: params,
          paramsSerializer: function(params) {
            return qs.stringify(params);
          },
       
        }
      )
      .then((res) => {
        commit("SET_ORDERS_TO_SHIP", res.data);
        console.log(res);
      })
      .catch((error) => console.log(error));
  },
  loadFulfilledOrders({ commit }) {
    const params = {};
    params.user = localStorage.getItem('User');
    axios
      .get(
        process.env.VUE_APP_FULFILLED, 
        {
          params: params,
          paramsSerializer: function(params) {
            return qs.stringify(params);
          },
       
        }
      )
      .then((res) => {
        commit("SET_FULFILLED_ORDERS", res.data);
        console.log(res);
      })
      .catch((error) => console.log(error));
  },
};

const mutations = {
  SET_ORDERS: function(state, data) {
    state.orders = data;
  },
  SET_TIRES: function(state, data) {
    state.tires = data;
  },
  SET_PENDING_ORDERS: function(state, data) {
    state.pendingOrders = data;
    console.log(data)
  },
  SET_ORDERS_TO_SHIP: function(state, data) {
    state.ordersToShip = data;
  },
  SET_FULFILLED_ORDERS: function(state, data) {
    state.fulfilledOrders = data;
  },
  ADD_USER: function (state) {
    if (state.user_id === "613ec02db1e17c7e8ca2e67f") {
      localStorage.setItem('userName', "Nic Schmietenknop")
    } else if ( state.user_id === "613ec04f91d3af3c81075fe8") {
      localStorage.setItem('userName', "Chad Hartle")
    }
  },
  LOADING_STATUS: function (state, payload) {
    state.loading = payload.user
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
