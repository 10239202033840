<template>
  <div class="orders--page">
  

      <SideNav/>
  
    <div class="orders--container">
      <Orders/>
    </div>
  </div>
</template>

<script>
import Orders from "../components/Orders";
import SideNav from "../components/SideNav";

export default {
  components: {
    Orders,
    SideNav
  },


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.orders--page {
  width: 100vw;
  display: flex;
  flex-direction: row;
}




.orders--container {
  width: stretch;
  padding: 0px 20px;

}

</style>
