<template>
  <div class="login--page">
    <img class="logo--login" src="../assets/logo.png" />
    <Login />
    <!-- <button @click="resetPassword()">reset email</button> -->
  </div>
</template>

<script>
import Login from "../components/Login";
import axios from "axios";

export default {
  components: {
    Login,
  },

  methods: {
    resetPassword: async function() {
      await axios
        .post("http://localhost:3000/api/resetpassword", {
            email: "orders@treadtires.ca"
        })
        .then((res) => {
          console.log("email sent", res);

        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style>
.login--page {
  width: 100vw;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
