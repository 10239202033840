<template>
  <div class="orders--page">
   
   <SideNav/>
    <div class="orders--container">
      <ShippingAdmin/>
    </div>
  </div>
</template>

<script>


import ShippingAdmin from "../components/ShippingAdmin";
import SideNav from "../components/SideNav";

export default {
  components: {
   SideNav,
    ShippingAdmin
  },


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>



</style>