<template>
  <div>
    <div class="login--container">
      <h2>Dashboard login</h2>
      <p>Please enter login credentials below</p>

      
            <input class="login--input" v-model="emailAddress" type="text" placeholder="Email address" />
            <div class="error" v-if="$v.emailAddress.$dirty">
              <p v-if="!$v.email.required">Field is required</p>
            </div>
            <div class="error" v-if="!$v.emailAddress.email">
              Must be valid email address
            </div>

            <input class="login--input" v-model="password" type="password" placeholder="Password" />
            <div class="error" v-if="$v.password.$dirty">
              <p v-if="!$v.password.required">Field is required</p>
            </div>
            

      <!-- <input class="login--input" v-model="email" placeholder="email" /> -->
     
      <button :disabled="!$v.emailAddress.email || emailAddress.length === 0 || password.length  <3" class="login--button" @click="logIn()" ><div v-if="loading===false">Login</div> <clip-loader v-if="loading===true" :loading="loading" :color="color"></clip-loader></button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { router } from "../routes";
import { mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import ClipLoader   from 'vue-spinner/src/ClipLoader.vue'

export default {
  data() {
    return {
      emailAddress: "",
      password: "",
      loading: false,
      color: '#e3e3e3',
      size: "large"
    };
  },

  validations: {
  
    emailAddress: {
      required,
      email,
    },
    password: {
      required,
    },
   
  },

  computed: {
    ...mapGetters([""]),
  },
  components: {
ClipLoader 
  },

  methods: {
    logIn: async function() {
    
      this.loading = true;
      await axios
        .post(
          "https://realm.mongodb.com/api/client/v2.0/app/application-0-ysnrm/auth/providers/local-userpass/login",
          {
            username: this.emailAddress,
            password: this.password,
          }
        )
        .then((res) => {
          let token = res.data.access_token;
          let user = res.data.user_id;
          localStorage.setItem("User", user);
          let storageUser = localStorage.getItem("User");
          console.log(storageUser);
          this.loading = false;

          if (storageUser === "613ec02db1e17c7e8ca2e67f") {
            localStorage.setItem("userName", "Nic Schmietenknop");
            console.log(localStorage.getItem("userName"));
          } else if (storageUser === "613ec04f91d3af3c81075fe8") {
            localStorage.setItem("userName", "Chad Hartle");
            console.log(localStorage.getItem("userName"));
          } else if (storageUser === "60d9616e4e8a83ec3c066ded") {
            localStorage.setItem("userName", "Shipping dashboard");
          } else if (storageUser === "614bd7fb0569d4b7dbdad0c3") {
            localStorage.setItem("userName", "Sean Curtis");
          } else if (storageUser === "60c5b765cf9bd57266e05b9e") {
            localStorage.setItem("userName", "Kelso Curtis");
          }

          if (token) {
            this.$store.commit({
              type: "ADD_USER",
              user: storageUser,
            });
            if (
              user === "60c5b765cf9bd57266e05b9e" ||
              user === "613ec02db1e17c7e8ca2e67f" ||
              user === "613ec04f91d3af3c81075fe8" || 
              user === "614bd7fb0569d4b7dbdad0c3"
            ) {
              router.push({ path: "/Admin-dashboard" });
            } else if (user == "60d9616e4e8a83ec3c066ded") {
              router.push({ path: "/Shipping-dashboard" });
            }
          } else {
            alert("user does not exist");
            this.loading = false
          }
          console.log(res);
        }).catch(err => alert(err))
    },
  },
};
</script>

<style>

button:disabled {
  background-color: rgb(240, 206, 206);
}
.logo--login {
  width: 150px;
}

.error {
  font-size: 16px;
  font-weight: 400;
  
  display: flex;
  justify-self: left;
  margin-left: 10px;
}
.login--container {
  width: 700px;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  font-size: 24px;
  font-weight: 500;
  border: solid 1px #e3e3e3;
  padding: 50px 100px;
  border-radius: 7px;
}

.login--input {
  height: 65px;
  border: none;
  font-size: 18px;
  padding: 15px;
 border: solid 1px rgb(241, 241, 241);
  outline: none;
  background-color: rgb(241, 241, 241);
  border-radius: 7px;
  margin: 5px;
}

.login--input:focus {
  height: 65px;
  border: solid 1px rgb(66, 66, 66);
  font-size: 18px;
  padding: 15px;

 
  background-color: rgb(241, 241, 241);
  border-radius: 7px;
  margin: 5px;
}

.login--button {
  border: none;
  outline: none;
  height: 60px;
  border-radius: 7px;
  font-size: 18px;
  font-weight: 500;
  margin: 20px 5px;
  display: flex;
  justify-content: center;
  background-color: #a21a1a;
  color: white;
  align-items: center;
  transition: 0.2s;
}

.login--button:active {
   transform: scale(0.97);
}
</style>
