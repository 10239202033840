<template>
  <div>
    <div class="orders">
      <div class="title">
        {{ orders.length }} orders
        <div class="explanation">Sell out new orders below</div>
        <div @click="loadOrders()" class="refresh">
          Refresh <img class="icon" src="../assets/refresh.svg" />
        </div>
      </div>

      <div class="title--border"></div>

      <div v-if="loading === true" class="loading--overlay">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
        ></pulse-loader>
      </div>
      <div class="order--row" v-for="(order, index) in orders" :key="index.id">
        <div class="order--item">
          <div class="order--item--1">
            <div class="Order--number--date">
              <span> Order #{{ order.orderNumber }}</span>
              <span class="order--date"> {{ order.orderDate }}</span>
            </div>
            <div>
              <input
                class="input--shipping"
                v-model="order.invoiceNumber"
                placeholder="Enter invoice number..."
              />
              <button
                class="button-action"
                :disabled="order.invoiceNumber.length < 5"
                @click="sellOrder(order)"
              >
                Sell out order
              </button>
            </div>
          </div>

          <div class="order--details--row">
            <div class="order--item--2">
              <span class="order--title">Customer details: </span>
              <span> {{ order.firstName }} {{ order.lastName }} </span>
              {{ order.emailAddress }}
              {{ order.phoneNumber }}
            </div>
            <div class="order--item--2">
              <span class="order--title">Shipping details </span>
              <span>
                {{ order.address.unitNumber }} {{ order.address.name }}
              </span>
              <span>
                {{ order.address.city }},
                {{ order.address.province }}
              </span>
              <span> {{ order.address.postalCode }} </span>
            </div>
            <div class="order--item--2">
              <span class="order--title">Product details </span>

              <div v-for="item in order.items" :key="item.id">
                <span v-if="item.universal.item">
                  Order:
                  {{ item.universal.quantity.$numberInt }} x
                  {{ item.universal.item.MSPN }}
                  <br />
                  {{ item.universal.item.Brand }}
                  {{ item.universal.item["Product Line"] }}

                  {{ item.universal.item["Size Description"] }}

                  <br />
                  @ $<span v-if="order.member == true">{{
                    item.universal.item.MemberPrice
                  }}</span>
                  <span v-else>{{ item.universal.item.FormattedPrice }}</span>
                </span>
                <span v-else>
                  Order:
                  {{ item.front.quantity.$numberInt }} x
                  {{ item.front.item.MSPN }}
                  <br />
                  {{ item.front.item.Brand }}
                  {{ item.front.item["Product Line"] }}
                  {{ item.front.item["Size Description"] }}<br />
                  @ $<span v-if="order.member == true">{{
                    item.front.item.MemberPrice
                  }}</span>
                  <span v-else>{{ item.front.item.FormattedPrice }}</span>
                  <br />
                  Order:
                  {{ item.rear.quantity.$numberInt }} x
                  {{ item.rear.item.MSPN }}
                  <br />
                  {{ item.rear.item.Brand }}
                  {{ item.rear.item["Product Line"] }}
                  {{ item.rear.item["Size Description"] }}<br />
                  @ $<span v-if="order.member == true">{{
                    item.rear.item.MemberPrice
                  }}</span>
                  <span v-else>{{ item.rear.item.FormattedPrice }}</span>
                </span>
                <span v-if="order.vehicle.length > 0">
                  <span v-for="vehicle in order.vehicle" :key="vehicle.id"
                    >Vehicle: {{ vehicle.vehicle.years.name.$numberInt }}
                    {{ vehicle.vehicle.make.name }}
                    {{ vehicle.vehicle.models.name }}
                    {{ vehicle.vehicle.trims.trim }}</span
                  >
                </span>
                <span v-else>
                  <span>Vehicle: none</span>
                </span>
              </div>
            </div>
            <div class="order--item--2">
              <span class="order--title">Order amount</span>
              <span>Subtotal: ${{ order.subtotal }} </span>
              <span>Shipping: ${{ order.shipping }} </span>
              <span>Shipping credit: -${{ order.shippingCredit }} </span>
              <span>Points redemption: -${{ order.pointsRedeemDollar }} </span>
              <span>Recycling fees: ${{ order.fees }}</span>
              <span>Taxes: ${{ order.taxes }}</span>
              <span>Total: ${{ order.total }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

var qs = require("qs");
var assert = require("assert");

var obj = qs.parse("a=c");
assert.deepEqual(obj, { a: "c" });

var str = qs.stringify(obj);
assert.equal(str, "a=c");

export default {
  data() {
    return {
      loading: false,

      // Note `isActive` is left out and will not appear in the rendered table
    };
  },
  components: {
    PulseLoader,
  },
  computed: {
    ...mapGetters([
      "orders",
      "ordersToShip",
      "fulfilledOrders",
      "user_id",
      "customers",
    ]),
    isDisabled() {
      if (!this.trackingNumber) {
        return true;
      } else {
        return false;
      }
    },
    userName() {
      let user = localStorage.getItem("userName");
      console.log(user);
      if (user === "Nic Schmietenknop") {
        return "NS";
      } else if (user === "Chad Hartle") {
        return "CH";
      } else if (user === "Sean Curtis") {
        return "SC";
      } else if (user === "Kelso Curtis") {
        return "KC";
      } else {
        return null;
      }
    },
  },

  mounted() {
    this.$store.dispatch("loadOrders");
    this.$store.dispatch("loadCustomers");
    setInterval(() => {
      console.log("yo");
      this.$store.dispatch("loadOrders");
    }, 60000);
  },
  methods: {
    loadOrders() {
      this.$store.dispatch("loadOrders");
    },

    sellOrder: async function(order) {
      const user = localStorage.getItem("User");
      const params = {};
      this.loading = true;
      (params.user = user),
        await axios
          .post(
            process.env.VUE_APP_SOLD_OUT_POST,
            {
              orderNumber: order.orderNumber,
              invoiceNumber: order.invoiceNumber,
            },
            {
              params: params,
              paramsSerializer: function(params) {
                return qs.stringify(params);
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.$store.dispatch("loadOrders");
            this.loading = false;
          })
          .catch((error) => console.log(error));
      this.loading = false;
    },
  },
};
</script>

<style>
.loading--overlay {
  position: absolute;
  top: 210px;
  right: 700px;
}

.explanation {
  font-size: 18px;
  font-weight: 400;
  margin-left: 20px;
}

.refresh {
  position: absolute;
  right: 30px;
  font-size: 16px;
  padding: 10px 20px;
  background-color: rgb(211, 214, 219);
  border-radius: 10px;
  font-weight: 500;
  color: black;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.2s;
}

.refresh:active {
  transform: scale(0.97);
}

.button-action {
  background-color: #9c3030 !important;
  padding: 10px;
  margin: 2px;
  height: 44px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  color: white;
  border: 0px solid;
  width: 200px;
}

.button-action:disabled {
  background-color: #9c30303d !important;
  color: white;
}

.input--shipping {
  border: solid 2px rgb(207, 207, 207);
  outline: none;
  border-radius: 5px;
  height: 44px;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  transition: 2s;
  width: 220px;
}

.tabs {
  width: 100%;
}
.orders {
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  align-items: flex-start;
  width: 98%;
  padding: 40px 20px 20px 20px;
}

.title {
  font-size: 28px;
  font-weight: 500;
  position: relative;
  margin-bottom: 10px;
  background-color: rgb(238, 238, 238);
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 30px 20px;
  border-radius: 10px;
}

.title--border {
  border: solid 1px rgb(238, 238, 238);
  width: 100%;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.order--row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  /* border: solid 1px rgb(241, 241, 241); */
  display: flex;
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.06);
  background-color: white;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding: 20px;
  text-align: left;
  border-radius: 7px;
  margin: 14px 0px;
  width: 100%;
}

.order--enter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order--item {
  width: 100%;
}

.order--title {
  font-weight: 500;
  margin-bottom: 10px;
}

.order--item--1 {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order--details--row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: left;
  /* align-items: top; */
  flex-wrap: wrap;
  min-height: 300px;
  margin-top: 13px;
  padding: 20px;
  background-color: rgb(247, 247, 247);
  border-radius: 5px;
}

.order--item--2 {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  width: 260px;
  min-height: 210px;
  margin-right: 10px;
}

.Order--number--date {
  display: flex;
  flex-direction: column;
}

.order--date {
  font-size: 18px;
  font-weight: 400;
  margin-top: 5px;
}
</style>
