<template>
  <div class="orders">
    <div class="title">
      <div class="explanation--search">Search past orders:</div>
      <input
        v-model="searchNumber"
        placeholder="Enter order number.."
        class="order--search"
      />
      <div @click="loadOrders()" class="refresh">
        Refresh <img class="icon" src="../assets/refresh.svg" />
      </div>
    </div>

    <div class="title--border"></div>
    <div v-if="loading === true" class="loading--overlay">
      <pulse-loader
        :loading="loading"
        :color="color"
        :size="size"
      ></pulse-loader>
    </div>
    <b-modal v-model="showModal">
      Create call-tags for provided address and email PDF to provided email
      address. Enter the shipping cost in the provided input field.
    </b-modal>

    <b-modal v-model="showModal2">
      If returning because of our error: refund total amount listed below via
      stripe dashboard and issue credit memo via Costar. <br />
      <br />
      If returning with no error: refund total total amount listed below minus
      the return shipping cost via stripe dashboard and issue credit memo via
      Costar.
    </b-modal>

    <b-modal v-model="showModal3">
      Send notice to customer that we have rejected tires and we will be sending
      them back. <br />
      <br />
      Once they confirm and accept we will send C.O.D via loomis. <br />
      <br />After clicking button below the order will be moved back to "Past
      orders".
    </b-modal>
    <b-tabs content-class="mt-3">
      <b-tab>
        <template #title> Awaiting tags ({{ filteredList.length }}) </template>
        <div class="order--row" v-for="order in filteredList" :key="order.id">
          <div class="order--item">
            <div class="order--item--1">
              <div class="Order--number--date">
                <span
                  >Order #{{ order.orderNumber }} | Invoice #{{
                    order.invoiceNumber
                  }}</span
                >
                <span class="order--date">
                  {{ order.orderDate }} - {{ order.userName }}</span
                >
              </div>
              <div class="order--enter">
                <img
                  @click="showModal = !showModal"
                  class="help"
                  src="../assets/help.svg"
                />
                <input
                  class="input--shipping"
                  v-model="order.returnShippingCost"
                  placeholder="Enter shipping cost here.."
                />
                <button
                  class="button-action"
                  :disabled="order.returnShippingCost.length < 1"
                  @click="shipTags(order)"
                >
                  Email & tags sent
                </button>
              </div>
            </div>
            <div class="order--details--row">
              <div class="order--item--2">
                <span class="order--title">Customer details: </span>
                <span> {{ order.firstName }} {{ order.lastName }} </span>
                {{ order.emailAddress }} {{ order.phoneNumber }}
              </div>
              <div class="order--item--2">
                <span class="order--title">Shipping details </span>
                <span>
                {{ order.address.unitNumber }} {{ order.address.name }}
              </span>
              <span>
                {{ order.address.city }},
                {{ order.address.province }}
              </span>
              <span> {{ order.address.postalCode }} </span>
                <span> Tracking: {{ order.trackingNumber }}</span>
              </div>
              <div class="order--item--2">
                <span class="order--title">Product details </span>
                <div v-for="item in order.items" :key="item.id">
                  <span v-if="item.universal.item">
                    Order:
                    {{ item.universal.quantity.$numberInt }} x
                    {{ item.universal.item.MSPN }}
                    <br />
                    {{ item.universal.item.Brand }}
                    {{ item.universal.item["Product Line"] }}

                    {{ item.universal.item["Size Description"] }}

                    <br />
                    @ ${{ item.universal.item.RetailPrice }}
                  </span>
                  <span v-else>
                    Order:
                    {{ item.front.quantity.$numberInt }} x
                    {{ item.front.item.MSPN }}
                    <br />
                    {{ item.front.item.Brand }}
                    {{ item.front.item["Product Line"] }}
                    {{ item.front.item["Size Description"] }}<br />
                    @ ${{ item.front.item.RetailPrice }}
                    <br />
                    Order:
                    {{ item.rear.quantity.$numberInt }} x
                    {{ item.rear.item.MSPN }}
                    <br />
                    {{ item.rear.item.Brand }}
                    {{ item.rear.item["Product Line"] }}
                    {{ item.rear.item["Size Description"] }}<br />
                    @ {{ item.rear.item.RetailPrice }}
                  </span>
                  <span v-if="order.vehicle.length > 0">
                    <span v-for="vehicle in order.vehicle" :key="vehicle.id"
                      >Vehicle: {{ vehicle.vehicle.years.name.$numberInt }}
                      {{ vehicle.vehicle.make.name }}
                      {{ vehicle.vehicle.models.name }}
                      {{ vehicle.vehicle.trims.trim }}</span
                    >
                  </span>
                  <span v-else>
                    <span>Vehicle: none</span>
                  </span>
                </div>
              </div>
              <div class="order--item--2">
                <span class="order--title">Order amount</span>
                <span>Subtotal: ${{ order.subtotal }} </span>
                <span>Recycling fees: ${{ order.fees }}</span>
                <span>Taxes: ${{ order.taxes }}</span>
                <span>Total: ${{ order.total }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab>
        <template #title>
          Tags printed/in transit ({{ filteredList2.length }})
        </template>
        <div
          class="order--row"
          v-for="order2 in filteredList2"
          :key="order2.id"
        >
          <div class="order--item" >
            <div class="order--item--1">
              <div class="Order--number--date">
                <span
                  >Order #{{ order2.orderNumber }} | Invoice #{{
                    order2.invoiceNumber
                  }}</span
                >
                <span class="order--date">
                  {{ order2.orderDate }} - {{ order2.userName }}</span
                >
              </div>
              <div class="order--enter">
                <input
                  class="checkbox"
                  v-model="order2.returnedCheckbox"
                  type="checkbox"
                />
                <button
                  @click="itemReturnedandRejected(order2)"
                  :disabled="order2.returnedCheckbox === false"
                  class="button-action"
                >
                  Reject refund
                </button>
                <button
                  @click="itemReturnedandAccepted(order2)"
                  :disabled="order2.returnedCheckbox === false"
                  class="button-action"
                >
                  Accept refund
                </button>
              </div>
            </div>
            <div class="order--details--row">
              <div class="order--item--2">
                <span class="order--title">Customer details: </span>
                <span> {{ order2.firstName }} {{ order2.lastName }} </span>
                {{ order2.emailAddress }} {{ order2.phoneNumber }}
              </div>
              <div class="order--item--2">
                <span>
                {{ order2.address.unitNumber }} {{ order2.address.name }}
              </span>
              <span>
                {{ order2.address.city }},
                {{ order2.address.province }}
              </span>
              <span> {{ order2.address.postalCode }} </span>
                <span> Tracking: {{ order2.trackingNumber }}</span>
                <span> Return shipping: ${{ order2.returnShippingCost }} </span>
              </div>
              <div class="order--item--2">
                <span class="order--title">Product details </span>

                <div v-for="item in order2.items" :key="item.id">
                  <span v-if="item.universal.item">
                    Order:
                    {{ item.universal.quantity.$numberInt }} x
                    {{ item.universal.item.MSPN }}
                    <br />
                    {{ item.universal.item.Brand }}
                    {{ item.universal.item["Product Line"] }}

                    {{ item.universal.item["Size Description"] }}

                    <br />
                    @ ${{ item.universal.item.RetailPrice }}
                  </span>
                  <span v-else>
                    Order:
                    {{ item.front.quantity.$numberInt }} x
                    {{ item.front.item.MSPN }}
                    <br />
                    {{ item.front.item.Brand }}
                    {{ item.front.item["Product Line"] }}
                    {{ item.front.item["Size Description"] }}<br />
                    @ ${{ item.front.item.RetailPrice }}
                    <br />
                    Order:
                    {{ item.rear.quantity.$numberInt }} x
                    {{ item.rear.item.MSPN }}
                    <br />
                    {{ item.rear.item.Brand }}
                    {{ item.rear.item["Product Line"] }}
                    {{ item.rear.item["Size Description"] }}<br />
                    @ {{ item.rear.item.RetailPrice }}
                  </span>
                  <span v-if="order2.vehicle.length > 0">
                    <span v-for="vehicle in order2.vehicle" :key="vehicle.id"
                      >Vehicle: {{ vehicle.vehicle.years.name.$numberInt }}
                      {{ vehicle.vehicle.make.name }}
                      {{ vehicle.vehicle.models.name }}
                      {{ vehicle.vehicle.trims.trim }}</span
                    >
                  </span>
                  <span v-else>
                    <span>Vehicle: none</span>
                  </span>
                </div>
              </div>
              <div class="order--item--2">
                <span class="order--title">Order amount</span>
                <span>Subtotal: ${{ order2.subtotal }} </span>
                <span>Recycling fees: ${{ order2.fees }}</span>
                <span>Taxes: ${{ order2.taxes }}</span>
                <span>Total: ${{ order2.total }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab>
        <template #title>
          Accepted returns ({{ filteredList3.length }})
        </template>
        <div
          class="order--row"
          v-for="order3 in filteredList3"
          :key="order3.id"
        >
          <div class="order--item">
            <div class="order--item--1">
              <div class="Order--number--date">
                <span
                  >Order #{{ order3.orderNumber }} | Invoice #{{
                    order3.invoiceNumber
                  }}</span
                >
                <span class="order--date">
                  {{ order3.orderDate }} - {{ order3.userName }}</span
                >
              </div>
              <div class="order--enter">
                <img
                  class="help"
                  @click="showModal2 = !showModal2"
                  src="../assets/help.svg"
                />
                <input
                  class="checkbox"
                  v-model="order3.returnedCheckbox"
                  type="checkbox"
                />
                <button
                  :disabled="order3.returnedCheckbox === false"
                  class="button-action"
                  @click="cardRefunded(order3)"
                >
                  Card refund complete
                </button>
              </div>
            </div>
            <div class="order--details--row">
              <div class="order--item--2">
                <span class="order--title">Customer details: </span>
                <span> {{ order3.firstName }} {{ order3.lastName }} </span>
                {{ order3.emailAddress }} {{ order3.phoneNumber }}
              </div>
              <div class="order--item--2">
                <span class="order--title">Shipping details </span>
                <span>
                {{ order3.address.unitNumber }} {{ order3.address.name }}
              </span>
              <span>
                {{ order3.address.city }},
                {{ order3.address.province }}
              </span>
              <span> {{ order3.address.postalCode }} </span>
                <span> Tracking: {{ order3.trackingNumber }}</span>
                <span> Return shipping: ${{ order3.returnShippingCost }} </span>
              </div>
              <div class="order--item--2">
                <span class="order--title">Product details </span>

                <div v-for="item in order3.items" :key="item.id">
            <span v-if="item.universal.item">
              Order:
              {{ item.universal.quantity.$numberInt }} x
              {{ item.universal.item.MSPN }}
              <br />
              {{ item.universal.item.Brand }}
              {{ item.universal.item["Product Line"] }}

              {{ item.universal.item["Size Description"] }}

              <br />
              @ ${{ item.universal.item.RetailPrice }}
            </span>
            <span v-else>
              Order:
              {{ item.front.quantity.$numberInt }} x
              {{ item.front.item.MSPN }}
              <br />
              {{ item.front.item.Brand }}
              {{ item.front.item["Product Line"] }}
              {{ item.front.item["Size Description"] }}<br />
              @ ${{ item.front.item.RetailPrice }}
              <br />
              Order:
              {{ item.rear.quantity.$numberInt }} x
              {{ item.rear.item.MSPN }}
              <br />
              {{ item.rear.item.Brand }}
              {{ item.rear.item["Product Line"] }}
              {{ item.rear.item["Size Description"] }}<br />
              @ {{ item.rear.item.RetailPrice }}
            </span>
            <span v-if="order3.vehicle.length > 0">
              <span v-for="vehicle in order3.vehicle" :key="vehicle.id"
                >Vehicle: {{ vehicle.vehicle.years.name.$numberInt }}
                {{ vehicle.vehicle.make.name }}
                {{ vehicle.vehicle.models.name }}
                {{ vehicle.vehicle.trims.trim }}</span
              >
            </span>
            <span v-else>
              <span>Vehicle: none</span>
            </span>
            </div>
              </div>
              <div class="order--item--2">
                <span class="order--title">Order amount</span>
                <span>Subtotal: ${{ order3.subtotal }} </span>
                <span>Recycling fees: ${{ order3.fees }}</span>
                <span>Taxes: ${{ order3.taxes }}</span>
                <span>Total: ${{ order3.total }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab>
        <template #title>
          Declined returns ({{ filteredList4.length }})
        </template>
        <div
          class="order--row"
          v-for="order4 in filteredList4"
          :key="order4.id"
        >
          <div class="order--item" v-for="item in order4.items" :key="item.id">
            <div class="order--item--1">
              <div class="Order--number--date">
                <span
                  >Order #{{ order4.orderNumber }} | Invoice #{{
                    order4.invoiceNumber
                  }}</span
                >
                <span class="order--date">
                  {{ order4.orderDate }} - {{ order4.userName }}</span
                >
              </div>
              <div class="order--enter">
                <img
                  @click="showModal3 = !showModal3"
                  src="../assets/help.svg"
                  class="help"
                />
                <input
                  class="checkbox"
                  v-model="order4.returnedCheckbox"
                  type="checkbox"
                />
                <button
                  :disabled="order4.returnedCheckbox === false"
                  class="button-action"
                  @click="moveToPastOrders(order4)"
                >
                  Send back tires
                </button>
              </div>
            </div>
            <div class="order--details--row">
              <div class="order--item--2">
                <span class="order--title">Customer details: </span>
                <span> {{ order4.firstName }} {{ order4.lastName }} </span>
                {{ order4.emailAddress }} {{ order4.phoneNumber }}
              </div>
              <div class="order--item--2">
                <span class="order--title">Shipping details </span>
                <span>
                {{ order4.address.unitNumber }} {{ order4.address.name }}
              </span>
              <span>
                {{ order4.address.city }},
                {{ order4.address.province }}
              </span>
              <span> {{ order4.address.postalCode }} </span>
                <span> Tracking: {{ order4.trackingNumber }}</span>
                <span> Return shipping: ${{ order4.returnShippingCost }} </span>
              </div>
              <div class="order--item--2">
                <span class="order--title">Product details </span>

            <div v-for="item in order4.items" :key="item.id">
            <span v-if="item.universal.item">
              Order:
              {{ item.universal.quantity.$numberInt }} x
              {{ item.universal.item.MSPN }}
              <br />
              {{ item.universal.item.Brand }}
              {{ item.universal.item["Product Line"] }}

              {{ item.universal.item["Size Description"] }}

              <br />
              @ ${{ item.universal.item.RetailPrice }}
            </span>
            <span v-else>
              Order:
              {{ item.front.quantity.$numberInt }} x
              {{ item.front.item.MSPN }}
              <br />
              {{ item.front.item.Brand }}
              {{ item.front.item["Product Line"] }}
              {{ item.front.item["Size Description"] }}<br />
              @ ${{ item.front.item.RetailPrice }}
              <br />
              Order:
              {{ item.rear.quantity.$numberInt }} x
              {{ item.rear.item.MSPN }}
              <br />
              {{ item.rear.item.Brand }}
              {{ item.rear.item["Product Line"] }}
              {{ item.rear.item["Size Description"] }}<br />
              @ {{ item.rear.item.RetailPrice }}
            </span>
            <span v-if="order4.vehicle.length > 0">
              <span v-for="vehicle in order4.vehicle" :key="vehicle.id"
                >Vehicle: {{ vehicle.vehicle.years.name.$numberInt }}
                {{ vehicle.vehicle.make.name }}
                {{ vehicle.vehicle.models.name }}
                {{ vehicle.vehicle.trims.trim }}</span
              >
            </span>
            <span v-else>
              <span>Vehicle: none</span>
            </span>
            </div>
              </div>
              <div class="order--item--2">
                <span class="order--title">Order amount</span>
                <span>Subtotal: ${{ order4.subtotal }} </span>
                <span>Recycling fees: ${{ order4.fees }}</span>
                <span>Taxes: ${{ order4.taxes }}</span>
                <span>Total: ${{ order4.total }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab>
        <template #title> Past returns ({{ filteredList5.length }}) </template>
        <div
          class="order--row"
          v-for="order5 in filteredList5"
          :key="order5.id"
        >
          <div class="order--item" v-for="item in order5.items" :key="item.id">
            <div class="order--item--1">
              <div class="Order--number--date">
                <span
                  >Order #{{ order5.orderNumber }} | Invoice #{{
                    order5.invoiceNumber
                  }}</span
                >
                <span class="order--date">
                  {{ order5.orderDate }} - {{ order5.userName }}</span
                >
              </div>
              <div class="order--enter">
                <!-- <button @click="modalShow = !modalShow" class="button-action">
              Start refund
            </button> -->
              </div>
            </div>
            <div class="order--details--row">
              <div class="order--item--2">
                <span class="order--title">Customer details: </span>
                <span> {{ order5.firstName }} {{ order5.lastName }} </span>
                {{ order5.emailAddress }} {{ order5.phoneNumber }}
              </div>
              <div class="order--item--2">
                <span class="order--title">Shipping details </span>
                <span>
                  {{ order5.address.unitNumber }} {{ order5.address.name }}
                </span>
                <span>
                  {{ order5.address.city }}
                  {{ order5.address.province }}
                </span>
                <span> {{ order5.address.postalCode }} </span>
                <span> Tracking: {{ order5.trackingNumber }}</span>
                <span> Return shipping: ${{ order5.returnShippingCost }} </span>
              </div>
              <div class="order--item--2">
                <span class="order--title">Product details </span>

                <div v-for="item in order5.items" :key="item.id">
                  <span v-if="item.universal.item">
                    Order:
                    {{ item.universal.quantity.$numberInt }} x
                    {{ item.universal.item.MSPN }}
                    <br />
                    {{ item.universal.item.Brand }}
                    {{ item.universal.item["Product Line"] }}

                    {{ item.universal.item["Size Description"] }}

                    <br />
                    @ ${{ item.universal.item.RetailPrice }}
                  </span>
                  <span v-else>
                    Order:
                    {{ item.front.quantity.$numberInt }} x
                    {{ item.front.item.MSPN }}
                    <br />
                    {{ item.front.item.Brand }}
                    {{ item.front.item["Product Line"] }}
                    {{ item.front.item["Size Description"] }}<br />
                    @ ${{ item.front.item.RetailPrice }}
                    <br />
                    Order:
                    {{ item.rear.quantity.$numberInt }} x
                    {{ item.rear.item.MSPN }}
                    <br />
                    {{ item.rear.item.Brand }}
                    {{ item.rear.item["Product Line"] }}
                    {{ item.rear.item["Size Description"] }}<br />
                    @ {{ item.rear.item.RetailPrice }}
                  </span>
                  <span v-if="order5.vehicle.length > 0">
                    <span v-for="vehicle in order5.vehicle" :key="vehicle.id"
                      >Vehicle: {{ vehicle.vehicle.years.name.$numberInt }}
                      {{ vehicle.vehicle.make.name }}
                      {{ vehicle.vehicle.models.name }}
                      {{ vehicle.vehicle.trims.trim }}</span
                    >
                  </span>
                  <span v-else>
                    <span>Vehicle: none</span>
                  </span>
                </div>
              </div>
              <div class="order--item--2">
                <span class="order--title">Order amount</span>
                <span>Subtotal: ${{ order5.subtotal }} </span>
                <span>Recycling fees: ${{ order5.fees }}</span>
                <span>Taxes: ${{ order5.taxes }}</span>
                <span>Total: ${{ order5.total }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import axios from "axios";

var qs = require("qs");
var assert = require("assert");

var obj = qs.parse("a=c");
assert.deepEqual(obj, { a: "c" });

var str = qs.stringify(obj);
assert.equal(str, "a=c");

export default {
  data() {
    return {
      loading: false,
      searchNumber: "",
      trackingNumber: "",
      showModal2: false,
      shippingTags: "",
      showModal: false,
      showModal3: false,
      one: false,
      two: false,
      three: false,
      four: false,

      // Note `isActive` is left out and will not appear in the rendered table
    };
  },
  components: {
    PulseLoader,
  },
  computed: {
    ...mapGetters(["orders", "ordersToShip", "fulfilledOrders", "user_id"]),
    sendTagsDisabled() {
      if (this.shippingTags.length < 1) {
        return true;
      } else {
        return false;
      }
    },
    filteredList() {
      return this.fulfilledOrders.filter((order) => {
        return (
          order.refundStarted === true &&
          order.refundInTransit === false &&
          order.refundAccepted === false &&
          order.refundRejected === false &&
          order.refundComplete === false &&
          order.orderNumber.includes(this.searchNumber)
        );
      });
    },
    filteredList2() {
      return this.fulfilledOrders.filter((order) => {
        return (
          order.refundStarted === true &&
          order.refundInTransit === true &&
          order.refundAccepted === false &&
          order.refundRejected === false &&
          order.refundComplete === false &&
          order.orderNumber.includes(this.searchNumber)
        );
      });
    },
    filteredList3() {
      return this.fulfilledOrders.filter((order) => {
        return (
          order.refundStarted === true &&
          order.refundInTransit === true &&
          order.refundAccepted === true &&
          order.refundRejected === false &&
          order.refundComplete === false &&
          order.orderNumber.includes(this.searchNumber)
        );
      });
    },
    filteredList4() {
      return this.fulfilledOrders.filter((order) => {
        return (
          order.refundStarted === true &&
          order.refundInTransit === true &&
          order.refundAccepted === false &&
          order.refundRejected === true &&
          order.refundComplete === false &&
          order.orderNumber.includes(this.searchNumber)
        );
      });
    },
    filteredList5() {
      return this.fulfilledOrders.filter((order) => {
        return (
          order.refundStarted === true &&
          order.refundInTransit === true &&
          order.refundAccepted === true &&
          order.refundRejected === false &&
          order.refundComplete === true &&
          order.orderNumber.includes(this.searchNumber)
        );
      });
    },
    isDisabled() {
      if (!this.trackingNumber) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    shipTags: async function(order) {
      const user = localStorage.getItem("User");
      console.log(order);
      this.loading = true;
      const params = {};
      (params.user = user),
        await axios
          .post(
            process.env.VUE_APP_FULFILLED_PUT,
            {
              firstName: order.firstName,
              lastName: order.lastName,
              emailAddress: order.emailAddress,
              phoneNumber: order.phoneNumber,
              address: order.address,
              items: order.items,
              taxes: order.taxes,
              fees: order.fees,
              subtotal: order.subtotal,
              total: order.total,
              vehicle: order.vehicle,
              orderNumber: order.orderNumber,
              trackingNumber: order.trackingNumber,
              invoiceNumber: order.invoiceNumber,
              userName: order.userName,
              orderDate: order.orderDate,
              refundConfirmationCheckbox: false,
              returnedCheckbox: false,
              stripeRefundedCheckbox: false,
              returnShippingCost: order.returnShippingCost,
              refundStarted: true,
              refundInTransit: true,
              refundAccepted: false,
              refundRejected: false,
              refundComplete: false,
            },
            {
              params: params,
              paramsSerializer: function(params) {
                return qs.stringify(params);
              },
            }
          )
          .then(() => {
            this.$store.dispatch("loadFulfilledOrders");
            console.log("loadpage");
            this.loading = false;
          })
          .catch((error) => console.log(error));
    },
    itemReturnedandAccepted: async function(order2) {
      const user = localStorage.getItem("User");
      console.log("processrefund");
      this.loading = true;
      const params = {};
      (params.user = user),
        await axios
          .post(
            process.env.VUE_APP_FULFILLED_PUT,
            {
              firstName: order2.firstName,
              lastName: order2.lastName,
              emailAddress: order2.emailAddress,
              phoneNumber: order2.phoneNumber,
              address: order2.address,
              items: order2.items,
              orderNumber: order2.orderNumber,
              trackingNumber: order2.trackingNumber,
              invoiceNumber: order2.invoiceNumber,
              orderDate: order2.orderDate,
              userName: order2.userName,
              refundConfirmationCheckbox: false,
              returnedCheckbox: false,
              stripeRefundedCheckbox: false,
              returnShippingCost: order2.returnShippingCost,
              refundStarted: true,
              refundInTransit: true,
              refundAccepted: true,
              refundRejected: false,
              refundComplete: false,
            },
            {
              params: params,
              paramsSerializer: function(params) {
                return qs.stringify(params);
              },
            }
          )
          .then(() => {
            this.$store.dispatch("loadFulfilledOrders");
            console.log("loadpage");
            this.loading = false;
          })
          .catch((error) => console.log(error));
    },
    itemReturnedandRejected: async function(order3) {
      this.loading = true;
      const user = localStorage.getItem("User");
      console.log("processrefund");
      const params = {};
      (params.user = user),
        await axios
          .post(
            process.env.VUE_APP_FULFILLED_PUT,
            {
              firstName: order3.firstName,
              lastName: order3.lastName,
              emailAddress: order3.emailAddress,
              phoneNumber: order3.phoneNumber,
              address: order3.address,
              items: order3.items,
              orderNumber: order3.orderNumber,
              trackingNumber: order3.trackingNumber,
              invoiceNumber: order3.invoiceNumber,
              orderDate: order3.orderDate,
              userName: order3.userName,
              refundConfirmationCheckbox: false,
              returnedCheckbox: false,
              stripeRefundedCheckbox: false,
              returnShippingCost: order3.returnShippingCost,
              refundStarted: true,
              refundInTransit: true,
              refundAccepted: false,
              refundRejected: true,
              refundComplete: false,
            },
            {
              params: params,
              paramsSerializer: function(params) {
                return qs.stringify(params);
              },
            }
          )
          .then(() => {
            this.$store.dispatch("loadFulfilledOrders");
            console.log("loadpage");
            this.loading = false;
          })
          .catch((error) => console.log(error));
    },
    cardRefunded: async function(order4) {
      const user = localStorage.getItem("User");
      this.loading = true;
      console.log("processrefund");
      const params = {};
      (params.user = user),
        await axios
          .post(
            process.env.VUE_APP_FULFILLED_PUT,
            {
              firstName: order4.firstName,
              lastName: order4.lastName,
              emailAddress: order4.emailAddress,
              phoneNumber: order4.phoneNumber,
              address: order4.address,
              items: order4.items,
              orderNumber: order4.orderNumber,
              trackingNumber: order4.trackingNumber,
              invoiceNumber: order4.invoiceNumber,
              orderDate: order4.orderDate,
              userName: order4.userName,
              refundConfirmationCheckbox: false,
              returnedCheckbox: false,
              stripeRefundedCheckbox: false,
              returnShippingCost: order4.returnShippingCost,
              refundStarted: true,
              refundInTransit: true,
              refundAccepted: true,
              refundRejected: false,
              refundComplete: true,
            },
            {
              params: params,
              paramsSerializer: function(params) {
                return qs.stringify(params);
              },
            }
          )
          .then(() => {
            this.$store.dispatch("loadFulfilledOrders");
            console.log("loadpage");
            this.loading = false;
          })
          .catch((error) => console.log(error));
    },
    moveToPastOrders: async function(order5) {
      this.loading = true;
      const user = localStorage.getItem("User");
      console.log("processrefund");
      const params = {};
      (params.user = user),
        await axios
          .post(
            process.env.VUE_APP_FULFILLED_PUT,
            {
              firstName: order5.firstName,
              lastName: order5.lastName,
              emailAddress: order5.emailAddress,
              phoneNumber: order5.phoneNumber,
              address: order5.address,
              items: order5.items,
              orderNumber: order5.orderNumber,
              trackingNumber: order5.trackingNumber,
              invoiceNumber: order5.invoiceNumber,
              refundConfirmationCheckbox: false,
              orderDate: order5.orderDate,
              userName: order5.userName,
              returnedCheckbox: false,
              stripeRefundedCheckbox: false,
              returnShippingCost: order5.returnShippingCost,
              refundStarted: false,
              refundInTransit: false,
              refundAccepted: false,
              refundRejected: false,
              refundComplete: false,
            },
            {
              params: params,
              paramsSerializer: function(params) {
                return qs.stringify(params);
              },
            }
          )
          .then(() => {
            this.$store.dispatch("loadFulfilledOrders");
            console.log("loadpage");
            this.loading = false;
          })
          .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.$store.dispatch("loadFulfilledOrders");
  },
};
</script>

<style>
.checkbox {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  padding: 20px;
}

.help {
  width: 40px;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 100px;
  padding: 8px;
}

.help:hover {
  background-color: rgb(235, 235, 235);
}
.explanation--search {
  font-weight: 500;
  font-size: 18px;
}
.order--search {
  height: 45px;
  width: 500px;

  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: solid 2px rgb(209, 209, 209);
  margin-left: 20px;
  outline: none;
}

.order--search:focus {
  border: solid 2px rgb(168, 168, 168);
}

.shipping--cost--input {
  height: 45px;
  border-radius: 5px;
  margin-right: 5px;
  outline: none;
  border: solid 1 px rgb(230, 230, 230);
}
</style>
