import Vue from "vue";
import VueRouter from "vue-router";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Shipping from "./pages/Shipping";
import AdminShippingPage from "./pages/AdminShippingPage";
import AdminFulfilledPage from "./pages/AdminFulfilledPage";
import ReturnPage from "./pages/ReturnPage";
import ShippingReturns from "./pages/ShippingReturns";


// import store from "./store.js";
Vue.use(VueRouter);

function guardMyroute(to, from, next) {
  let isAuthenticated = false;
  const user = localStorage.getItem('User')
  if (user == "60d9616e4e8a83ec3c066ded") {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  if (isAuthenticated) {
    next(); // allow to enter route
  } else  {
    next("/"); // go to '/login';
  }
}

function guardMyAdminroute(to, from, next) {
  let isAuthenticated = false;
  const user = localStorage.getItem('User')
  if (user === "60c5b765cf9bd57266e05b9e" || user === "613ec02db1e17c7e8ca2e67f" || user === "613ec04f91d3af3c81075fe8" || 
  user === "614bd7fb0569d4b7dbdad0c3") {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  if (isAuthenticated) {
    next(); // allow to enter route
  } else  {
    next("/"); // go to '/login';
  }
}
// function guardMyAdminroute(to, from, next) {
//   let isAuthenticated = false;
//   if (store.state.orders.user_id == "60c5b765cf9bd57266e05b9e") {
//     isAuthenticated = true;
//   } else {
//     isAuthenticated = false;
//   }
//   if (isAuthenticated) {
//     next(); // allow to enter route
//   } else {
//     next("/"); // go to '/login';
//   }
// }

export const router = new VueRouter({

  mode: "history",

  routes: [
    { path: "/", component: Login },
   
   
    {
      path: "/Admin-dashboard",
      component: Admin,
      beforeEnter: guardMyAdminroute,
    },

    {
      path: "/Admin-shipping",
      component: AdminShippingPage,
      beforeEnter: guardMyAdminroute,
    },
    {
      path: "/Admin-fulfilled",
      component: AdminFulfilledPage,
      beforeEnter: guardMyAdminroute,
    },
    {
      path: "/Admin-refunds",
      component: ReturnPage,
      beforeEnter: guardMyAdminroute,
    },
    {
      path: "/Shipping-dashboard",
      component: Shipping,
      beforeEnter: guardMyroute,
    },
    {
      path: "/Shipping-returns",
      component: ShippingReturns,
      beforeEnter: guardMyroute,
    },
    
   
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
