<template>
  <div class="orders">
    <div class="title">
      {{ ordersToShip.length }} orders
      <div class="explanation">See orders to be shipped below</div>
      <div @click="loadOrdersToShip()" class="refresh">
        Refresh <img class="icon" src="../assets/refresh.svg" />
      </div>
    </div>

    <div class="title--border"></div>
    <div v-if="loading === true" class="loading--overlay">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
        ></pulse-loader>
      </div>
    <div class="order--row" v-for="order2 in ordersToShip" :key="order2.id">
      
      <div class="order--item">
        <div class="order--item--1">
          <span>Order #{{ order2.orderNumber }}</span>
          <div>
          <input
          class="input--shipping"
          v-model="order2.trackingNumber"
          placeholder="Enter tracking number"
        />
        <button
          class="button-action"
          :disabled="order2.trackingNumber.length <6"
          @click="shipOrder(order2)"
        >
          Ship order
        </button>
        </div>
   
        </div>
        <div class="order--details--row">
          <div class="order--item--2">
            <span class="order--title">Customer details: </span>
            <span> {{ order2.firstName }} {{ order2.lastName }} </span>
            {{ order2.emailAddress }}
            {{order2.phoneNumber}}
          </div>
          <div class="order--item--2">
            <span class="order--title">Shipping details </span>
              <span> {{order2.address.unitNumber}} {{ order2.address.name }} </span>
              <span>
                {{ order2.address.city }}
                {{ order2.address.province }}
              </span>
              <span> {{ order2.address.postalCode }} </span>
           
          </div>
          <div class="order--item--2">
            <span class="order--title">Product details </span>
                   <div v-for="item in order2.items" :key="item.id">

            <span v-if="item.universal.item">
              Order:
              {{ item.universal.quantity.$numberInt }} x
              {{ item.universal.item.MSPN }}
              <br />
              {{ item.universal.item.Brand }}
              {{ item.universal.item["Product Line"] }}

              {{ item.universal.item["Size Description"] }}

              <br />
              @ ${{ item.universal.item.RetailPrice }}
            </span>
            <span v-else>
              Order:
              {{ item.front.quantity.$numberInt }} x
              {{ item.front.item.MSPN }}
              <br />
              {{ item.front.item.Brand }}
              {{ item.front.item["Product Line"] }}
              {{ item.front.item["Size Description"] }}<br />
              @ ${{ item.front.item.RetailPrice }}
              <br />
              Order:
              {{ item.rear.quantity.$numberInt }} x
              {{ item.rear.item.MSPN }}
              <br />
              {{ item.rear.item.Brand }}
              {{ item.rear.item["Product Line"] }}
              {{ item.rear.item["Size Description"] }}<br />
              @ {{ item.rear.item.RetailPrice }}
            </span>
            <span v-if="order2.vehicle.length > 0">
              <span v-for="vehicle in order2.vehicle" :key="vehicle.id"
                >Vehicle: {{ vehicle.vehicle.years.name.$numberInt }}
                {{ vehicle.vehicle.make.name }}
                {{ vehicle.vehicle.models.name }}
                {{ vehicle.vehicle.trims.trim }}</span
              >
            </span>
            <span v-else>
              <span>Vehicle: none</span>
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

var qs = require("qs");
var assert = require("assert");

var obj = qs.parse("a=c");
assert.deepEqual(obj, { a: "c" });

var str = qs.stringify(obj);
assert.equal(str, "a=c");
export default {
  data() {
    return {
      modalShow: false,
      trackingNumber: "",
      loading: false,
      // Note `isActive` is left out and will not appear in the rendered table
    };
  },
  components: {
    PulseLoader,
  },
  computed: {
    ...mapGetters(["orders", "ordersToShip", "fulfilledOrders", "user_id"]),
    // isDisabled() {
    //   if (this.trackingNumber.length < 8) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },

  mounted() {
    this.$store.dispatch("loadOrdersToShip");
  },
  methods: {
    openShippingModal() {},
    loadOrdersToShip() {
      this.$store.dispatch("loadOrdersToShip");
    },

    shipOrder: async function(order2) {
      const params = {};
      const user = localStorage.getItem("User");
      this.loading = true;
      params.user = user;
      await axios
        .post(process.env.VUE_APP_CREATE_TRACKER, {
          trackingNumber: order2.trackingNumber,
        })
        .then(async(res) => {
          console.log(res.data.data)
          let trackingCode = res.data.data.tracking_code;
          await axios
            .post(
              process.env.VUE_APP_FULFILLED_POST,
              {
                orderNumber: order2.orderNumber,
                trackingNumber: trackingCode,
              },
              {
                params: params,
                paramsSerializer: function(params) {
                  return qs.stringify(params);
                },
              }
            )
            .then(() => {
              this.$store.dispatch("loadOrdersToShip");
              this.loading = false;
            });
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style></style>
