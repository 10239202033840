<template>
  <div id="app">
    <transition name="moveInUp">
      <router-view></router-view>
    </transition>
  
  </div>
</template>

<script>


export default {
  name: 'App',
  
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

#app {
   font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3d3d3d;
  margin: 0;
  padding: 0;
}

.h1 {

}

h2 {
  font-size: 28px!important;
  font-weight: 600!important;
}

p {
  font-size: 18px!important;
  font-weight: 400!important;
}
</style>
