<template>
   <div  @click="logOut()">Logout <img class="icon" src="../assets/logout.svg"></div> 
</template>

<script>
import { router } from "../routes";
export default {

methods: {
    logOut: function () {
        localStorage.removeItem('User'),
        localStorage.removeItem('userName'),
        router.push({ path: "/" })
    }
}
    
}
</script>



<style>

.icon {
    width: 18px;
    margin-left: 5px;
}

.logout {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 50px;
    font-weight: 500;
    font-size: 16px;
    height: 50px;
    position: absolute;
    bottom: 15px;
    left: 20px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.11);
    border-radius: 50px;
    background-color: white;
    

}

</style>