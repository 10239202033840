<template>
  <div class="orders">
    <div class="title">
      <div class="explanation--search">Search past orders:</div>
      <input
        v-model="searchNumber"
        placeholder="Enter order number.."
        class="order--search"
      />
      <div @click="loadOrders()" class="refresh">
        Refresh <img class="icon" src="../assets/refresh.svg" />
      </div>
    </div>

    <div class="title--border"></div>

    <div v-if="loading === true" class="loading--overlay">
      <pulse-loader
        :loading="loading"
        :color="color"
        :size="size"
      ></pulse-loader>
    </div>

    <div class="order--row" v-for="order3 in filteredList" :key="order3.id">
      <div class="order--item">
        <div class="order--item--1">
          <div class="Order--number--date">
            <span
              >Order #{{ order3.orderNumber }} | Invoice #{{
                order3.invoiceNumber
              }}</span
            >
            <span class="order--date">
              {{ order3.orderDate }} - {{ order3.userName }}</span
            >
          </div>
          <div class="order--enter">
            <input
              v-model="order3.refundConfirmationCheckbox"
              type="checkbox"
              class="checkbox"
            />
            <button
              :disabled="order3.refundConfirmationCheckbox === false"
              @click="processRefund(order3)"
              class="button-action"
            >
              Start refund
            </button>
          </div>
        </div>
        <div class="order--details--row">
          <div class="order--item--2">
            <span class="order--title">Customer details: </span>
            <span> {{ order3.firstName }} {{ order3.lastName }} </span>
            {{ order3.emailAddress }}
            {{ order3.phoneNumber }}
          </div>
          <div class="order--item--2">
            <span class="order--title">Shipping details </span>
            <span>
              {{ order3.address.unitNumber }} {{ order3.address.name }}
            </span>
            <span>
              {{ order3.address.city }}
              {{ order3.address.province }}
            </span>
            <span> {{ order3.address.postalCode }} </span>
            <span> Tracking: {{ order3.trackingNumber }}</span>
          </div>
          <div class="order--item--2">
            <span class="order--title">Product details </span>

            <div v-for="item in order3.items" :key="item.id">
              <span v-if="item.universal.item">
                Order:
                {{ item.universal.quantity.$numberInt }} x
                {{ item.universal.item.MSPN }}
                <br />
                {{ item.universal.item.Brand }}
                {{ item.universal.item["Product Line"] }}

                {{ item.universal.item["Size Description"] }}

                <br />
                @ $<span v-if="order3.member == true">{{
                  item.universal.item.MemberPrice
                }}</span>
                <span v-else>{{ item.universal.item.FormattedPrice }}</span>
              </span>
              <span v-else>
                Order:
                {{ item.front.quantity.$numberInt }} x
                {{ item.front.item.MSPN }}
                <br />
                {{ item.front.item.Brand }}
                {{ item.front.item["Product Line"] }}
                {{ item.front.item["Size Description"] }}<br />
                @ $<span v-if="order3.member == true">{{
                  item.front.item.MemberPrice
                }}</span>
                <span v-else>{{ item.front.item.FormattedPrice }}</span>
                <br />
                Order:
                {{ item.rear.quantity.$numberInt }} x
                {{ item.rear.item.MSPN }}
                <br />
                {{ item.rear.item.Brand }}
                {{ item.rear.item["Product Line"] }}
                {{ item.rear.item["Size Description"] }}<br />
                @ $<span v-if="order3.member == true">{{
                  item.rear.item.MemberPrice
                }}</span>
                <span v-else>{{ item.rear.item.FormattedPrice }}</span>
              </span>
              <span v-if="order3.vehicle.length > 0">
                <span v-for="vehicle in order3.vehicle" :key="vehicle.id"
                  >Vehicle: {{ vehicle.vehicle.years.name.$numberInt }}
                  {{ vehicle.vehicle.make.name }}
                  {{ vehicle.vehicle.models.name }}
                  {{ vehicle.vehicle.trims.trim }}</span
                >
              </span>
              <span v-else>
                <span>Vehicle: none</span>
              </span>
            </div>
          </div>
          <div class="order--item--2">
            <span class="order--title">Order amount</span>
            <span>Subtotal: ${{ order3.subtotal }} </span>
            <span>Shipping: ${{ order3.shipping }} </span>
            <span>Shipping credit: -${{ order3.shippingCredit }} </span>
            <span>Points redemption: -${{ order3.pointsRedeemDollar }} </span>
            <span>Recycling fees: ${{ order3.fees }}</span>
            <span>Taxes: ${{ order3.taxes }}</span>
            <span>Total: ${{ order3.total }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

var qs = require("qs");
var assert = require("assert");

var obj = qs.parse("a=c");
assert.deepEqual(obj, { a: "c" });

var str = qs.stringify(obj);
assert.equal(str, "a=c");

export default {
  data() {
    return {
      searchNumber: "",
      loading: false,

      // Note `isActive` is left out and will not appear in the rendered table
    };
  },
  components: {
    PulseLoader,
  },
  computed: {
    ...mapGetters(["orders", "ordersToShip", "fulfilledOrders", "user_id"]),
    filteredList() {
      return this.fulfilledOrders.filter((order) => {
        return (
          order.refundStarted === false &&
          order.orderNumber.includes(this.searchNumber)
        );
      });
    },
    isDisabled() {
      if (!this.trackingNumber) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    processRefund: async function(order3) {
      const user = localStorage.getItem("User");
      this.loading = true;
      console.log("processrefund");
      const params = {};
      (params.user = user),
        await axios
          .post(
            process.env.VUE_APP_FULFILLED_PUT,
            {
              firstName: order3.firstName,
              lastName: order3.lastName,
              emailAddress: order3.emailAddress,
              phoneNumber: order3.phoneNumber,
              address: order3.address,
              items: order3.items,
              taxes: order3.taxes,
              shipping: order3.shipping,
              discounts: order3.discounts,
              fees: order3.fees,
              subtotal: order3.subtotal,
              total: order3.total,
              vehicle: order3.vehicle,
              orderNumber: order3.orderNumber,
              trackingNumber: order3.trackingNumber,
              invoiceNumber: order3.invoiceNumber,
              userName: order3.userName,
              orderDate: order3.orderDate,
              refundConfirmationCheckbox: false,
              returnedCheckbox: false,
              stripeRefundedCheckbox: false,
              returnShippingCost: "",
              refundStarted: true,
              refundInTransit: false,
              refundAccepted: false,
              refundRejected: false,
              refundComplete: false,
            },
            {
              params: params,
              paramsSerializer: function(params) {
                return qs.stringify(params);
              },
            }
          )
          .then(() => {
            this.loading = false;
            this.$store.dispatch("loadFulfilledOrders");
         
          })
          .catch((error) => console.log(error));
      this.loading = false;
    },
  },

  mounted() {
    this.$store.dispatch("loadFulfilledOrders");
  },
};
</script>

<style>
.explanation--search {
  font-weight: 500;
  font-size: 18px;
}
.order--search {
  height: 45px;
  width: 500px;

  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: solid 2px rgb(209, 209, 209);
  margin-left: 20px;
  outline: none;
}

.order--search:focus {
  border: solid 2px rgb(168, 168, 168);
}
</style>
