import axios from "axios";
// var qs = require("qs");
// var assert = require("assert");

// var obj = qs.parse("a=c");
// assert.deepEqual(obj, { a: "c" });

// var str = qs.stringify(obj);
// assert.equal(str, "a=c");

// var withArray = qs.parse("a[]=b&a[]=c");
// assert.deepEqual(withArray, { a: ["b", "c"] });

const state = {
  customers: []
};

const getters = {
  customers: (state) => state.customers,
  
  

};

const actions = {
  loadCustomers({ commit }) {
    // const params = {};
    // params.user = localStorage.getItem('User');

    axios.get(
      "https://webhooks.mongodb-realm.com/api/client/v2.0/app/application-0-ysnrm/service/tread/incoming_webhook/customers",
        
      )
      .then((res) => {
        commit("SET_CUSTOMERS", res.data);
        console.log(res);
      })
      .catch((error) => console.log(error));
  },

};

const mutations = {
  SET_CUSTOMERS: function(state, data) {
    state.customers= data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};