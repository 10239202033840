<template>
  <div class="orders--page">
   
   <SideNav2/>
    <div class="orders--container">
      <ShippingOrders/>
    </div>
  </div>
</template>

<script>


import ShippingOrders from "../components/ShippingOrders";
import SideNav2 from "../components/SideNav2";

export default {
  components: {
   SideNav2,
    ShippingOrders
  },


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>



</style>
