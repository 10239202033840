import Vuex from 'vuex';
import Vue from 'vue'
import orders from './modules/orders'
import customers from './modules/customers'


// import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

// const vuexLocal = new VuexPersistence({
//     modules: ['orders'],
//     storage: window.localStorage
//   })

export default new Vuex.Store ({
    // plugins: [vuexLocal.plugin],
    modules: {
        orders,
        customers
    }
});