<template>
  <div class="orders--page">
   
   <SideNav/>
    <div class="orders--container">
      <Fulfilled/>
    </div>
  </div>
</template>

<script>


import Fulfilled from "../components/Fulfilled";
import SideNav from "../components/SideNav";

export default {
  components: {
   SideNav,
    Fulfilled
  },


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>



</style>