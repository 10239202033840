<template>
  <div class="side--nav--container">
    <img src="../assets/logo.png" class="logo" />

    <div class="account--info">
      <img class="account-icon" src="../assets/account.svg" /> {{ name }}
    </div>

    <!-- <div class="pages--nav">
      <router-link to="/Admin-dashboard">
        <div class="sidebar--nav--item">
          New orders ({{ orders.length }})
        </div></router-link>
      
    </div> -->

    <div class="pages--nav">
      <router-link to="/Admin-dashboard">
        <div class="sidebar--nav--item">
          New orders ({{ orders.length }})
        </div></router-link
      >
      <router-link to="/Admin-shipping">
        <div class="sidebar--nav--item">Shipping</div></router-link
      >
      <router-link to="/Admin-fulfilled">
        <div class="sidebar--nav--item">Past orders</div></router-link
      >
      <router-link to="/Admin-refunds">
        <div class="sidebar--nav--item">Refunds</div></router-link
      >
    
       <!-- <router-link to="/Customers">
        <div class="sidebar--nav--item">Customers</div></router-link
      > -->
    </div>
    <div class="logout--side--nav"><Logout /></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logout from "../components/Logout";

export default {
  data: function() {
    return {
      name: localStorage.getItem("userName"),
    };
  },

  components: {
    Logout,
  },

  computed: {
    ...mapGetters([
      "orders",
      "ordersToShip",
      "fulfilledOrders",
      "user_id",
      "userName",
    ]),
    isDisabled() {
      if (!this.trackingNumber) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
a {
  text-decoration: none !important;
  color: #333333 !important;
}

.router-link {
  text-decoration: none !important;
}

.router-link-active {
  color: rgb(27, 56, 119) !important;
  font-weight: 400;
  width: 100%;

  display: flex;
  justify-content: left;
  background-color: rgb(245, 245, 245);
  border-radius: 7px;
  padding: 0px;
}

.account-icon {
  width: 30px;
  margin-right: 10px;
}

.account--info {
  background-color: rgb(249, 251, 252);
  margin-top: 100px;
  display: flex;
  justify-content: left;
  align-items: center;

  font-size: 17px;
  font-weight: 500;
  padding: 10px 15px;
  width: 85%;
  border-radius: 10px;
}

.logo {
  width: 120px;
  position: absolute;
  left: 100px;
  top: 20px;
}

.logout--side--nav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 50px;
  font-weight: 500;
  font-size: 16px;

  position: absolute;
  bottom: 15px;
  left: 30px;
  /* box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.11); */
  border-radius: 10px;
  background-color: rgb(249, 251, 252);
}

.logout--side--nav :hover {
  cursor: pointer;
}

.side--nav--container {
  height: 100vh;
  width: 420px;
  display: flex;
  flex-direction: column;

  align-items: center;
  position: sticky;
  top: 0px;
  background-color: rgb(238, 242, 245);
}

.pages--nav {
  padding: 20px;
  margin-top: 150px;
  background-color: rgb(249, 251, 252);
  border-radius: 10px;
  width: 85%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.sidebar--nav--item {
  font-size: 17px;
  cursor: pointer;
  margin: 10px;

  font-weight: 500;
}

.sidebar--nav--item:hover {
  color: black;
}

.sidebar--nav--item:active {
  color: black;
}
.sidebar--component {
  position: relative;
}
</style>
